.App {
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  color: white;
}

.App .navbar {
  flex-shrink: 0;
  color: black;
  background-color: gray;
}

.App .main-content {
  position: relative;
  height: 100%;
}

.leaflet-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
}

/* --- containers --- */
.vinka-containers {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: .75em;
  max-height: 100%;
}

.side-panel {
  overflow-y: auto;
  pointer-events: auto;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  padding: 1em;
  border-radius: .5em;
  background-color: rgba(0, 0, 0, .72);
  font-size: 0.8em;
}

.side-panel_search {
  overflow-y: initial;
  height: fit-content;
}

.date-range {
  cursor: pointer;
  margin-bottom: 15px;
  display: inline-block;
}

.range-button-width {
  width: 30%;
}

.error-daterange-message {
  font-size: 0.8rem;
  background-color: rgba(220, 53, 69, 0.9);
  color: white;
  padding: .25rem .5rem;
  margin-bottom: .5rem;
  border-radius: .25rem;
}

.close-btn-position {
  position: absolute;
  top: 5px;
  right: 10px;
}

.navbar {
  background: url('/bg.jpg') left top;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1;
  width: calc(100% - 10px);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  background-color: hsl(0, 0%, 100%);
}

.autocomplete-dropdown-inner {
  overflow-y: auto;
  position: relative;
  max-height: 300px;
  padding: 4px 0;
}

.autocomplete-dropdown-option {
  display: block;
  padding: 8px 12px;
  width: 100%;
  font-size: inherit;
  color: #000;
  cursor: default;
}

.autocomplete-dropdown-option:hover,
.autocomplete-dropdown-option-active {
  background-color: #DEEBFF;
}

.react-select__menu-list {
  color: #000;
}

.react-select__placeholder {
  color: #6c757d;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .72);
}

.modal-wrapper {
  overflow: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  max-width: 90vw;
  max-height: 90vh;
  min-width: 40vw;
  transform: translate(-50%, -50%);
  padding: 1em;
  border-radius: .5em;
  background-color: #fff;
  font-size: 0.8em;
  color: #000;
}

.confirm-modal-width {
  max-width: 60vw;
  min-width: 20vw;
}

.invalid-tooltip-inline {
  display: block;
  top: -5px;
  /*left: -3px;*/
  z-index: 0;
  font-size: 0.8rem;
}

.select-text {
  background-color: transparent;
  border: 0px solid;
  height: 20px;
  width: 160px;
  color: #FFF;
}

.select-text option {
  background-color: #333333;
}

@media only screen and (min-width: 768px) {
  .address-delete-btn {
    position: absolute;
    bottom: 0;
  }
}

/* --- containers-end --- */
